import dayjs from 'dayjs';
import { z } from 'zod';

import { Form } from '@/modules/form/@types';
import { DeepPartial } from '@/modules/form/@types-utils';
import { createForm } from '@/modules/form/form';

export function createGiftEditFormSchema() {
  return z.object({
    giftMessage: z.string().min(1, { message: 'This is a required field' }).min(1),
    giftFrom: z.string().min(1, { message: 'This is a required field' }).min(1),
    giftDate: z.string().refine(
      (dateStringified) => {
        if (!dateStringified) {
          return false;
        }

        const now = dayjs().startOf('minute');
        const date = dayjs(dateStringified);
        return date.isAfter(now);
      },
      { message: 'Select today or a future date' },
    ),
  });
}

export type GiftEditFormInput = z.input<ReturnType<typeof createGiftEditFormSchema>>;
export type GiftEditFormOutput = z.output<ReturnType<typeof createGiftEditFormSchema>>;

export type GiftEditForm = Form<GiftEditFormInput, GiftEditFormOutput>;

export function createGiftEditForm(defaultValues?: DeepPartial<GiftEditFormInput>): GiftEditForm {
  return createForm({
    validation: {
      mode: 'onChange',
      display: 'onTouched',
    },
    defaultValues,
    schema: createGiftEditFormSchema(),
  });
}

import { ComponentRef, useMemo, useRef } from 'react';
import dayjs from 'dayjs';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMDateInput } from '@/components/RMDateInput/RMDateInput';
import { RMDialogPanel } from '@/components/RMDialogPanel';
import { RMInput } from '@/components/RMInput/RMInput';
import { RMLink } from '@/components/RMLink/RMLink';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useTextareaResize } from '@/hooks/useTextareaResize';
import { InputContainer, InputController } from '@/modules/form/container';
import { useInputValue } from '@/modules/form/input';

import { GiftEditForm } from '../../forms/gift-edit.form';

import { Container } from './GiftEdit.styles';

interface GiftEditProps {
  open: boolean;
  storytellerName: string;
  form: GiftEditForm;
  timezone: string;
  welcomeLink: string;
  onClose: () => void;
  onSave: () => void;
}

export function GiftEdit({ open, storytellerName, form, timezone, welcomeLink, onClose, onSave }: GiftEditProps) {
  const textareaRef = useRef<ComponentRef<'textarea'>>();
  const giftMessage = useInputValue(form, 'giftMessage');
  useTextareaResize(textareaRef.current ?? null, giftMessage ?? '');

  const minDate = useMemo(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return dayjs(today).format('YYYY-MM-DD');
  }, []);

  return (
    <RMDialogPanel.Root open={open} onClose={onClose}>
      <RMDialogPanel.Title onClose={onClose}>{`Your gift message for ${storytellerName}`}</RMDialogPanel.Title>
      <RMDialogPanel.Content>
        <Container>
          <RMText type="sans" size="s" color="on-surface-secondary">
            Choose when to send your gift, and customize your gift message. We’ll also send an{' '}
            <a href={welcomeLink} target="_blank">
              <RMLink>overview</RMLink>
            </a>{' '}
            of how Remento works.
          </RMText>
          <RMSpacer spacing="2xl" direction="column" />
          <InputController form={form} path="giftDate">
            {(props) => (
              <>
                <RMDateInput
                  id="start-date"
                  type="datetime"
                  label="Gift delivery date"
                  placeholder="XX/XX/XXXX"
                  min={minDate}
                  defaultTime="06:00:00"
                  {...props}
                />
                <RMSpacer spacing="xs" direction="column" />
                <RMText size="xs" type="sans" color="on-surface-tertiary">
                  Your gift will be sent at the selected date and time in the {timezone} timezone.
                </RMText>
              </>
            )}
          </InputController>
          <RMSpacer spacing="2xl" direction="column" />
          <InputContainer form={form} path="giftFrom">
            {(props) => <RMInput id="gift-from" label="Who is the gift from?" {...props} />}
          </InputContainer>
          <RMSpacer spacing="2xl" direction="column" />
          <InputContainer form={form} path="giftMessage">
            {(props) => (
              <RMInput
                id="gift-message"
                label="Gift message"
                textarea
                {...props}
                ref={(ref) => {
                  if (ref) {
                    textareaRef.current = ref as HTMLTextAreaElement;
                    props.ref(ref);
                  }
                }}
              />
            )}
          </InputContainer>
        </Container>
      </RMDialogPanel.Content>
      <RMDialogPanel.Actions>
        <RMButton onClick={() => onSave()} fullWidth size="large" background="primary" autoLoading>
          Save
        </RMButton>
      </RMDialogPanel.Actions>
    </RMDialogPanel.Root>
  );
}
